<template>
  <form action="#" @submit.prevent="handleSubmit">
    <div class="common-modal-headline" :class="{ 'mb-4': inline }">
      <h4 class="common-modal-title">
        {{ isNew ? $t("company.add-new-company") : $t("company.edit-company") }}
      </h4>

      <span
        v-if="!inline"
        class="material-symbols-rounded common-modal-close"
        @click="$emit('onClose')"
      >
        close
      </span>
    </div>

    <hr v-if="!inline" class="my-3" />

    <b-alert variant="danger" dismissible class="mt-3" :show="Boolean(error)">{{
      error
    }}</b-alert>

    <!-- <div>
      <pre>company: {{ company }}</pre>
    </div>
    <div>
      <pre>form: {{ form }}</pre>
    </div> -->

    <div class="row">
      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="required">{{ $t("form.company-name") }}</label>
          <div class="input-group input-group-merge">
            <input
              v-model="form.name"
              class="form-control"
              :placeholder="$t('form.company-name-placeholder')"
              :class="{
                'is-invalid': submitted && $v.form.name.$error,
              }"
            />
            <div
              v-if="submitted && $v.form.name.$error"
              class="invalid-feedback"
            >
              <div v-if="!$v.form.name.required">
                {{ $t("form.company-name-req") }}
              </div>
              <div v-if="!$v.form.name.minLength">
                {{ $t("form.company-name-min", { min: 5 }) }}
              </div>
              <div v-if="!$v.form.name.maxLength">
                {{ $t("form.company-name-max", { max: 64 }) }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-group mb-3">
          <label class="required">{{ $t("form.fio") }}</label>
          <div class="input-group input-group-merge">
            <input
              v-model="form.representative_name"
              class="form-control"
              :placeholder="$t('form.fio-placeholder')"
              :class="{
                'is-invalid': submitted && $v.form.representative_name.$error,
              }"
            />
            <div
              v-if="submitted && $v.form.representative_name.$error"
              class="invalid-feedback"
            >
              <div v-if="!$v.form.representative_name.required">
                {{ $t("form.fio-req") }}
              </div>
              <div v-if="!$v.form.representative_name.minLength">
                {{ $t("form.fio-min", { min: 5 }) }}
              </div>
              <div v-if="!$v.form.representative_name.maxLength">
                {{ $t("form.fio-max", { max: 64 }) }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-group mb-0">
          <label class="required">{{ $t("form.contact") }}</label>
          <div class="input-group input-group-merge">
            <input
              v-model="form.contact"
              class="form-control"
              :placeholder="$t('form.contact-placeholder')"
              :class="{
                'is-invalid': submitted && $v.form.contact.$error,
              }"
            />
            <div
              v-if="submitted && $v.form.contact.$error"
              class="invalid-feedback"
            >
              <div v-if="!$v.form.contact.required">
                {{ $t("form.contact-req") }}
              </div>
              <div v-if="!$v.form.contact.minLength">
                {{ $t("form.contact-min", { min: 5 }) }}
              </div>
              <div v-if="!$v.form.contact.maxLength">
                {{ $t("form.contact-max", { max: 64 }) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group mb-3">
          <label class="required">{{ $t("form.login") }}</label>
          <div class="input-group input-group-merge">
            <input
              v-model="form.login"
              class="form-control"
              :placeholder="$t('form.login-placeholder')"
              :class="{
                'is-invalid': submitted && $v.form.login.$error,
              }"
            />
            <div
              v-if="submitted && $v.form.login.$error"
              class="invalid-feedback"
            >
              <div v-if="!$v.form.login.required">
                {{ $t("form.login-req") }}
              </div>
              <div v-if="!$v.form.login.minLength">
                {{ $t("form.login-min", { min: 5 }) }}
              </div>
              <div v-if="!$v.form.login.maxLength">
                {{ $t("form.login-max", { max: 20 }) }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-group mb-3">
          <label class="required">{{ $t("form.email") }}</label>
          <div class="input-group input-group-merge">
            <input
              v-model="form.email"
              class="form-control"
              :placeholder="$t('form.email-placeholder')"
              :class="{
                'is-invalid': submitted && $v.form.email.$error,
              }"
            />
            <div
              v-if="submitted && $v.form.email.$error"
              class="invalid-feedback"
            >
              <div v-if="!$v.form.email.required">
                {{ $t("form.email-req") }}
              </div>
              <div v-if="!$v.form.email.email">
                {{ $t("form.email-not-valid") }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-group mb-3">
          <label :class="{ required: isNew }">{{ $t("form.password") }}</label>
          <div class="input-group input-group-merge">
            <input
              v-model="form.password"
              :type="showPassw ? 'text' : 'password'"
              class="form-control"
              :placeholder="$t('form.password-placeholder')"
              :class="{
                'is-invalid': isNew && submitted && $v.form.password.$error,
              }"
            />
            <div
              class="input-group-append"
              @click="showPassw = !showPassw"
              role="button"
            >
              <div class="input-group-text">
                <span v-if="showPassw" class="material-symbols-rounded fz-18"
                  >visibility</span
                >
                <span v-else class="material-symbols-rounded fz-18"
                  >visibility_off</span
                >
              </div>
            </div>
            <div
              v-if="isNew && submitted && $v.form.password.$error"
              class="invalid-feedback"
            >
              <div v-if="!$v.form.password.required">
                {{ $t("form.password-req") }}
              </div>
              <div v-if="!$v.form.password.minLength">
                {{ $t("form.password-min", { min: 8 }) }}
              </div>
              <div v-if="!$v.form.password.maxLength">
                {{ $t("form.password-max", { max: 30 }) }}
              </div>
              <div v-if="!$v.form.password.valid">
                {{ $t("form.password-not-valid") }}
              </div>
            </div>
          </div>
        </div>

        <div class="form-group mb-0">
          <label>{{ $t("form.password_confirmation") }}</label>
          <div class="input-group input-group-merge">
            <input
              v-model="form.password_confirmation"
              :type="showPassw ? 'text' : 'password'"
              class="form-control"
              :placeholder="$t('form.password_confirmation-placeholder')"
              :class="{
                'is-invalid': submitted && $v.form.password_confirmation.$error,
              }"
            />
            <div
              class="input-group-append"
              @click="showPassw = !showPassw"
              role="button"
            >
              <div class="input-group-text">
                <span v-if="showPassw" class="material-symbols-rounded fz-18"
                  >visibility</span
                >
                <span v-else class="material-symbols-rounded fz-18"
                  >visibility_off</span
                >
              </div>
            </div>
            <div
              v-if="submitted && $v.form.password_confirmation.$error"
              class="invalid-feedback"
            >
              {{ $t("form.password_confirmation-not-valid") }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <hr v-if="!inline" class="mt-2 mb-1" />

    <div class="mt-3 text-center text-md-left">
      <button class="btn btn-primary" type="submit" :disabled="trySubmit">
        {{ isNew ? $t("btn.add-company") : $t("btn.save-changes") }}
      </button>
    </div>

    <!-- <pre>company: {{ company }}</pre> -->
    <!-- <pre>form: {{ form }}</pre> -->
  </form>
</template>

<script>
import { mapActions, mapState } from "pinia";
import { useGlobalStore } from "@/store/global";

import {
  required,
  minLength,
  maxLength,
  email,
} from "vuelidate/lib/validators";

export default {
  props: {
    company: {
      type: Object,
      default: () => {},
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: "",

      form: {
        name: "",
        representative_name: "",
        contact: "",
        login: "",
        email: "",
        password: "",
        password_confirmation: "",
      },

      showPassw: false,

      submitted: false,
      trySubmit: false,
    };
  },
  validations() {
    return {
      form: this.formRules,
    };
  },
  created() {
    this.initForm();
  },
  computed: {
    // ...mapState(useGlobalStore, ["timezones"]),
    isNew() {
      return !this.company?.id;
    },

    formRules() {
      let rules = {};

      rules.name = {
        required,
        minLength: minLength(5),
        maxLength: maxLength(64),
      };

      rules.representative_name = {
        required,
        minLength: minLength(5),
        maxLength: maxLength(64),
      };

      rules.contact = {
        required,
        minLength: minLength(5),
        maxLength: maxLength(64),
      };

      rules.login = {
        required,
        minLength: minLength(5),
        maxLength: maxLength(20),
      };

      rules.email = {
        required,
        email,
      };

      rules.password_confirmation = {
        valid: (value) => {
          return !(this.form.password && this.form.password !== value);
        },
      };

      if (this.isNew) {
        rules.password = {
          required,
          // valid: function (value) {
          //   const containsUppercase = /[A-Z]/.test(value);
          //   const containsLowercase = /[a-z]/.test(value);
          //   const containsNumber = /[0-9]/.test(value);
          //   const containsSpecial = /[#?!@$%^&*-]/.test(value);
          //   return (
          //     containsUppercase &&
          //     containsLowercase &&
          //     containsNumber &&
          //     containsSpecial
          //   );
          // },
          minLength: minLength(8),
          maxLength: maxLength(30),
        };
      }

      return rules;
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ["editCompany", "createCompany"]),
    initForm() {
      if (!this.isNew) {
        this.form = {
          id: this.company.id,
          name: this.company.name,
          representative_name: this.company.representative_name,
          contact: this.company.contact,
          login: this.company.admin?.name || "",
          email: this.company.admin?.email || "",
        };
      }
    },
    async handleSubmit() {
      this.submitted = true;
      this.error = "";

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.trySubmit = true;
        let req = null;

        if (this.isNew) {
          // Add new company
          // console.log('new company, data: ', this.form)
          req = await this.createCompany({ ...this.form });
        } else {
          // console.log('edit existed company, data: ', this.form)
          req = await this.editCompany({ ...this.form });
        }

        // console.log('handleSubmit, req.response: ', req.response)
        if (req?.response?.data?.message) {
          this.error = req.response.data.message;

          if (req.response?.status) {
            this.error = `[${req.response.status}] ${req.response.data.message}`;
          }
        } else {
          this.$emit("onSubmit");
        }

        this.trySubmit = false;
      }
    },
  },
  watch: {},
};
</script>
